import Vue from 'vue'
Object.assign(Vue.prototype, {
    setDefault(obj, obj2) {
        for (let k in obj2) {
            obj[k] = obj[k] || obj2[k];
        }
        return obj;
    },
    formatString(obj) {
        for (let o in obj) {

            if (obj[o] && obj[o] instanceof Array) {
                obj[o].map(r => this.formatString(r));
                continue;
            }
            if (obj[o] && obj[o] instanceof Object) {
                this.formatString(obj[o]);
                continue;
            }
            if (typeof (obj[o]) == 'string') {
                obj[o] = obj[o].replace(/\n/g, '<br/>')
            }

        }
    }
});
//时间格式化
Date.prototype.format = function (fmt) {
    var o = {
        "M+": this.getMonth() + 1,                 //月份
        "d+": this.getDate(),                    //日
        "h+": this.getHours(),                   //小时
        "m+": this.getMinutes(),                 //分
        "s+": this.getSeconds(),                 //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds()             //毫秒
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }

    return fmt;
}

Date.prototype.Format = Date.prototype.format;
