import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: '登录',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
        meta: { title: '登录' }
    },
    {
        path: '/app',
        meta: { title: '布局页面' },//手机版本
        component: () => import(/* webpackChunkName: "BasicLayout" */ '../views/app/Layout.vue'),
        redirect: "/app/index",
        children: [
            {
                path: '/app/index',
                name: '我的蔚来',
                component: () => import(/* webpackChunkName: "Home" */ '../views/app/Index.vue'),
                meta: { title: '我的蔚来' }
            },
            {
                path: '/app/bulletin',
                name: '公告',
                component: () => import(/* webpackChunkName: "Home" */ '../views/app/pages/sys/bulletin.vue'),
                meta: { title: '公告' }
            },
            {
                path: '/app/myTask',
                component: () => import(/* webpackChunkName: "Home" */ '../views/app/pages/my/MyTask.vue'),
                meta: { title: '我的任务' }
            },
            {
                path: '/app/myWorkReport',
                component: () => import(/* webpackChunkName: "Home" */ '../views/app/pages/my/MyWorkReport.vue'),
                meta: { title: '我的日报' }
            },
            {
                path: '/app/myWorkThisWeekReport',
                component: () => import(/* webpackChunkName: "Home" */ '../views/app/pages/my/MyWorkThisWeekReport.vue'),
                meta: { title: '我的周报' }
            },
            {
                path: '/app/myWorkThisYearReport',
                component: () => import(/* webpackChunkName: "Home" */ '../views/app/pages/my/MyWorkThisYearReport.vue'),
                meta: { title: '我的年报' }
            },
        ]
    },
    {
        path: '*',
        redirect: '/app'
    }
]


const router = new VueRouter({
    mode: 'hash',
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }

    let refreshToken = sessionStorage.getItem('userInfo');
    if (!refreshToken) {
        if (to.path !== '/login') {
            next({ path: '/login', replace: true });
        } else {
            next();
        }
    } else {
        next();
    }

    //next();
});


export default router
