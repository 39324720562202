import Vue from 'vue'
Object.assign(Vue.prototype, {
    $sysapi(funName) {
        if (!window['sys']) {
            console.error('系统API未初始化!');
            return;
        }
        const sys = window.sys;
        if (!sys[funName]) {
            console.error(`系统API未提供${funName}函数!`);
            return;
        }
        if (arguments[1]) {

            let args = [];
            for (let i = 1; i < arguments.length; i++) {
                args.push(arguments[i]);
            }
            return sys[funName].apply(this, args);
        }
        return sys[funName].apply(this);
    }
});