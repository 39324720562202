"use strict";
//import { Toast } from 'vant'

import Vue from 'vue';
import axios from "axios";
import { Promise } from 'core-js';
import router from '../router';
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';



let baseURL = 'https://wx.gdyqwl.com/api/v1/';
let globalURL = 'https://wx.gdyqwl.com/';
if (location.hostname.startsWith('localhost')) {
    baseURL = 'http://localhost:8082/api/v1/';
    globalURL = 'http://localhost:8082/';
}
//if (location.hostname.startsWith('192.168')) {
//    baseURL = 'http://192.168.101.25:8082/api/v1/';
//    globalURL = 'http://192.168.101.25:8082/';
//}

const config = {
    baseURL: baseURL,
    timeout: 3 * 60 * 1000, // Timeout
    withCredentials: false, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        if (!config.headers) {
            config.headers = {};
        }
        var token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        console.info(error);
        //Toast.fail(`远程请求出错：${error}`);
        return Promise.reject(error);
    }
);

_axios.interceptors.response.use(
    function (response) {
        if (response && response.data && !response.data.code) {
            //Toast.fail(`请求异常:${response.data.msg}`);
        }
        return response;
    },
    function (error) {

        //401 重新授权 
        if (error.toString().indexOf('401')) {
            sessionStorage.removeItem('userInfo');
            //Toast.fail(`用户登录信息丢失请重新登录`);
            router.push('/login');
            return;
        }
        console.info(error);
        //Toast.fail(`服务器响应出错：${error}`);
        return Promise.reject(error);
    }
);

Plugin.install = function (Vue/*, options*/) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.assign(Vue.prototype, {
        baseURL,
        globalURL,
        $get(url, config) {
            return new Promise((s, e) => { _axios.get(url, config || axios.defaults).then(d => s(d.data)).catch(d => e(d)); });

        },
        $post(url, data, config) {
            return new Promise((s, e) => { _axios.post(url, data, config || axios.defaults).then(d => s(d.data)).catch(d => e(d)); });
        },
        $download(url, data, config) {
            _axios.post(url, data, Object.assign({}, config || {}, axios.defaults, { responseType: 'blob' })).then(res => {
                let fileName = res.config.fileName || res.headers['content-disposition'].match(/filename=(.*)/ig)[0].split('=')[1];
                fileName = decodeURI(fileName);
                let url = window.URL.createObjectURL(res.data);
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            });
        }

    });
};

Vue.use(Plugin);

export default Plugin;
