import Vue from 'vue';
import './plugins/axios';
import './utils/sysapi';
import './utils/webApiUrl';
import './utils/extend';
import App from './App.vue';
import router from './router';
import "./assets/main.css";
import "./assets/color-dark.css"; /*深色主题*/
import './plugins/vant';
import './plugins/wx';


Vue.config.productionTip = false


Vue.prototype.user = JSON.parse(sessionStorage.getItem('userInfo') || '{}');


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')


