import Vue from 'vue'
Object.assign(Vue.prototype, {
    apiUrl: {
        loginUrl: 'UserInfo/Login',
        getUserList: 'UserInfo/GetUsersList',
        getAllUser: 'UserInfo/GetAllUserName',
        getUserMenu: 'UserInfo/GetUserMenu',
        changePwd: 'UserInfo/ChangePwd',
        addUser: 'UserInfo/Add',
        editUser: 'UserInfo/Edit',
        deleteUser: 'UserInfo/Delete',
        resetPwd: 'UserInfo/ResetPwd',
        getProjectList: 'Project/GetList',
        getProjectList2: 'Project/GetList2',
        addProject: 'Project/Add',
        editProject: 'Project/Edit',
        deleteProject: 'Project/Delete',
        closeProject: 'Project/Close',
        getProjectSel: 'Project/GetProjectSel',

        getTaskList: 'Task/GetList',
        addTask: 'Task/Add',
        editTask: 'Task/Edit',
        deleteTask: 'Task/Delete',

        transferTask: 'Task/Transfer',
        beginTask: 'Task/Begin',
        retractTask: 'Task/Retract',
        shelvedTask: 'Task/Shelved',
        restartTask: 'Task/Restart',
        completionTask: 'Task/Completion',
        exportTask: 'Task/Export',
        progressTask: 'Task/ProgressTask',
        expTask: 'Task/ExpTask',
        imgUploadUrl: 'Task/UploadImg',
        imgUploadUrlBase64: 'Task/UploadImgBase64',

        getWorkReportList: 'WorkReport/GetList',
        addWorkReport: 'WorkReport/Add',
        editWorkReport: 'WorkReport/Edit',
        exportWorkReport: 'WorkReport/Export',

        getWeekWorkReportList: 'WeekWorkReport/GetList',
        addWeekWorkReport: 'WeekWorkReport/Add',
        editWeekWorkReport: 'WeekWorkReport/Edit',
        exportWeekWorkReport: 'WeekWorkReport/Export',

        getRoleList: 'Role/GetList',
        addRole: 'Role/Add',
        editRole: 'Role/Edit',
        deleteRole: 'Role/Delete',
        bingToUser: 'Role/BingToUser',
        bingToMenu: 'Role/BingToMenu',

        getMenuList: 'Menu/GetList',
        addMenu: 'Menu/Add',
        editMenu: 'Menu/Edit',
        deleteMenu: 'Menu/Delete',
        getTopMenus: 'Menu/GetTopMenus',

        getMaterialList: 'Material/GetList',
        getMaterialTypeList: 'Material/GetTypeList',
        addMaterial: 'Material/Add',
        editMaterial: 'Material/Edit',
        deleteMaterial: 'Material/Delete',
        exportMaterial: 'Material/Export',
        importMaterial: 'Material/Import',
        getMateralCode: 'Material/GetMateralCode',

        getYearWorkReportList: 'YearWorkReport/GetList',
        addYearWorkReport: 'YearWorkReport/Add',
        editYearWorkReport: 'YearWorkReport/Edit',
        exportYearWorkReport: 'YearWorkReport/Export',
    }
});